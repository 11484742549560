import { render, staticRenderFns } from "./Geolocator.vue?vue&type=template&id=65af46c2&scoped=true&"
import script from "./Geolocator.vue?vue&type=script&lang=js&"
export * from "./Geolocator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65af46c2",
  null
  
)

export default component.exports