<!-- Created by init-app.js at Tue Mar 10 2020 16:02:41 GMT+0100 (GMT+01:00) -->
<template>
  <wgu-app-tpl>
    <!-- insert your app slots here  -->
  </wgu-app-tpl>
</template>

<script>
import WguAppTemplate from './WguAppTemplate.vue';
import { Style, Fill, Stroke } from 'ol/style';
import LayerUtil from '../src/util/Layer';
import { Mapable } from '../src/mixins/Mapable';
import colormap from 'colormap';

export default {
  name: 'my-wgu-app',
  mixins: [Mapable],
  components: {
    'wgu-app-tpl': WguAppTemplate
  },
  methods: {
    onMapBound () {
      const me = this;
      me.addStyles();
    },
    addStyles: function () {
      const me = this;
      // Set occupancy style
      const occupancyLayer = LayerUtil.getLayerByLid('gfz-obm-occupancy', me.map);
      if (occupancyLayer) {
        occupancyLayer.setStyle(me.occupancyStyle);
      }
      // Set completeness style
      const completenessLayer = LayerUtil.getLayerByLid('gfz-obm-completeness', me.map);
      if (completenessLayer) {
        completenessLayer.setStyle(me.completenessStyle);
      }
      // Set exposure style
      const nBuildingLayer = LayerUtil.getLayerByLid('gfz-gde-buildings', me.map);
      if (nBuildingLayer) {
        nBuildingLayer.setStyle(me.nBuildingStyle);
      }
      // Set structural style
      const structuralLayer = LayerUtil.getLayerByLid('gfz-gde-structural', me.map);
      if (structuralLayer) {
        structuralLayer.setStyle(me.structuralStyle);
      }
      // Set population style
      const populationLayer = LayerUtil.getLayerByLid('gfz-gde-population', me.map);
      if (populationLayer) {
        populationLayer.setStyle(me.populationStyle);
      }
    },
    nBuildingStyle (feature) {
      const me = this;
      const nBuildings = feature.get('number_buildings');
      const color = me.getColor(nBuildings, 0, 50, 'density');
      return me.getStyle(color);
    },
    structuralStyle (feature) {
      const me = this;
      const structural = feature.get('sum_structural');
      const color = me.getColor(structural, 0, 10000000, 'density');
      return me.getStyle(color);
    },
    populationStyle (feature) {
      const me = this;
      const population = feature.get('number_night');
      const color = me.getColor(population, 0, 1000, 'density');
      return me.getStyle(color);
    },
    completenessStyle (feature) {
      const me = this;
      const ratio = feature.get('ratio_norm');
      const color = me.getColor(ratio, 0, 100, 'winter');
      return me.getStyle(color);
    },
    clamp (value, low, high) {
      return Math.max(low, Math.min(value, high));
    },
    getColor (ratio, min, max, colorMapName) {
      const me = this;
      const steps = 50;
      const ramp = colormap({
        colormap: colorMapName,
        nshades: steps,
        format: 'rgbaString',
        alpha: [0.8, 0.8]
      });
      const f = Math.pow(me.clamp((ratio - min) / (max - min), 0, 1), 1 / 2);
      const index = Math.round(f * (steps - 1));
      return ramp[index];
    },
    getStyle (color) {
      const style = new Style({
        fill: new Fill({
          color
        }),
        stroke: new Stroke({
          color
        })
      });
      return style;
    },
    occupancyStyle (feature) {
      // styles: https://material.io/resources/icons/?style=baseline
      const symbols = {
        NULL: { color: '#666' },
        OC99: { color: '#666' },
        RES: { color: '#dc2300' },
        RES99: { color: '#dc2300' },
        RES1: { color: '#b84700' },
        RES2: { color: '#f33' },
        RES2A: { color: '#f00' },
        RES3: { color: '#b84747' },
        RES4: { color: '#b80047' },
        RES5: { color: '#99284c' },
        EDU99: { color: '#ff6' },
        EDU1: { color: '#e6ff00' },
        EDU2: { color: '#e6e64c' },
        EDU3: { color: '#cc0' },
        EDU4: { color: '#b3b300' },
        COM: { color: '#0047ff' },
        COM99: { color: '#0047ff' },
        COM1: { color: '#280099' },
        COM2: { color: '#4700b8' },
        COM3: { color: '#2300dc' },
        COM4: { color: '#00b8ff' },
        COM5: { color: '#0099ff' },
        COM6: { color: '#2323dc' },
        COM7: { color: '#039' },
        COM8: { color: '#d8e9e8' },
        COM9: { color: '#c3dfe0' },
        COM10: { color: '#72aeb6' },
        COM11: { color: '#9cf' },
        ASS99: { color: '#0cc' },
        ASS1: { color: '#23b8dc' },
        ASS2: { color: '#47b8b8' },
        ASS3: { color: '#33a3a3' },
        ASS4: { color: '#198a8a' },
        GOV99: { color: '#c63' },
        GOV1: { color: '#f63' },
        GOV2: { color: '#f96' },
        IND: { color: '#804c19' },
        IND99: { color: '#804c19' },
        IND1: { color: '#630' },
        IND2: { color: '#963' },
        AGR99: { color: '#355e00' },
        AGR1: { color: '#5c8526' },
        AGR2: { color: '#7da647' },
        AGR3: { color: '#94bd5e' },
        MIX: { color: '#940065' },
        MIX99: { color: '#940065' },
        MIX1: { color: '#94476b' },
        MIX2: { color: '#944794' },
        MIX3: { color: '#96c' },
        MIX4: { color: '#6b4794' },
        MIX5: { color: '#6b2394' },
        MIX6: { color: '#6b0094' },
        UNK: { color: '#666' }
      };
      const category = feature.get('occupancy');
      let style;

      if (category in symbols) {
        style = new Style({
          fill: new Fill({
            color: symbols[category].color
          })
        });
      } else {
        style = new Style({
          fill: new Fill({
            color: 'red'
          }),
          stroke: new Stroke({
            color: 'black'
          })
        });
      }

      return style;
    }
  }
}
</script>
